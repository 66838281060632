import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import NavBar from "components/Navbars/ColorNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import Testimonials from "components/Testimonials/Testimonials";
import FooterAboutUs from "components/Footers/FooterAboutUs.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <NavBar />
      <LandingPageHeader />
      <div className="wrapper">
        <div className="section text-center landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">
                  Right at your office, right at your doorstep. Save time and
                  trouble.
                </h2>
                <h5>
                  We have a medley of tires available Across many different
                  brands, sizes, and types. If you need brand new tires for the
                  season, we can bring them to you And install on-the-go at your
                  location.
                </h5>
                <br />
                <Button
                  className="btn-fill btn-round"
                  color="danger"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  Brands
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-settings-gear-65" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Tire installation</h4>
                    <p className="description">
                      It is crucial to have the right tires for the right
                      season. Have the correct tires to save precious tread
                      life.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-mobile" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Mobile Service</h4>
                    <p>
                      We will bring the service to your doorstep. Do not worry
                      about booking time off work to get your tires changed.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-settings" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Rim & Flat Repair</h4>
                    <p>
                      Our technicians repair your tire using both patches and
                      plugs to exclude all the concerns that may happen on the
                      road.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info">
                  <div className="icon icon-danger">
                    <i className="nc-icon nc-box" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Tire Storage</h4>
                    <p>
                      Free up some space with our storage service. We will bring
                      it to you when you need it.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center landing-section">
          <Container>
            <Testimonials />
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default LandingPage;
