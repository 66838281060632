import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import AboutUsHeader from "components/Headers/AboutUsHeader.js";
import FooterAboutUs from "components/Footers/FooterAboutUs.js";

function AboutUs() {
  // document.documentElement.classList.remove("nav-open");
  // React.useEffect(() => {
  //   document.body.classList.add("about-us");
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  //   return function cleanup() {
  //     document.body.classList.remove("about-us");
  //   };
  // });
  return (
    <>
      <ColorNavbar />
      <AboutUsHeader />
      <div className="main">
        <div className="section">
          <Container>
            <h3 className="title-uppercase">We provide great service.</h3>
            <p>
              At Tire Bank, We believe there is always a better way to save
              time. A modern innovation to keep our valued customers from
              spending less thoughts and time about unnecessary concerns. Our
              team is extremely passionate about fulfilling our customers’ needs
              and provide excellent customer service. Anywhere you go, we will
              be there to give you service that you need. Spend less time
              worrying.
            </p>
            <p>
              We live and work in Vancouver (Canada) all over the lower
              mainland. Our main goal is to expand all across Canada to provide
              our excellent service.
            </p>

            <div className="team-1">
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Our Team</h2>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-avatar">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {/* <img
                          alt="..."
                          src={require("assets/img/faces/clem-onojeghuo-3.jpg")}
                        /> */}
                      </a>
                    </div>
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="author">
                          <CardTitle tag="h4">Johnny Lee</CardTitle>
                          <h6 className="card-category text-muted">Founder</h6>
                        </div>
                      </a>
                      <p className="card-description text-center">
                        Teamwork is so important that it is virtually impossible
                        for you to reach the heights of your capabilities or
                        make the money that you want without becoming very good
                        at it.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-avatar">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {/* <img
                          alt="..."
                          src={require("assets/img/faces/joe-gardner-2.jpg")}
                        /> */}
                      </a>
                    </div>
                    <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="author">
                          <CardTitle tag="h4">Jeffrey Wong</CardTitle>
                          <h6 className="card-category text-muted">
                            Director of sales
                          </h6>
                        </div>
                      </a>
                      <p className="card-description text-center">
                        A group becomes a team when each member is sure enough
                        of himself and his contribution to praise the skill of
                        the others. No one can whistle a symphony. It takes an
                        orchestra to play it.
                      </p>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile card-plain">
                    <div className="card-avatar">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        {/* <img
                          alt="..."
                          src={require("assets/img/faces/erik-lucatero-2.jpg")}
                        /> */}
                      </a>
                    </div>
                    {/* <CardBody>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        <div className="author">
                          <CardTitle tag="h4">Jae Lee</CardTitle>
                          <h6 className="card-category text-muted">
                            PRODUCT MANAGER
                          </h6>
                        </div>
                      </a>
                      <p className="card-description text-center">
                        The strength of the team is each individual member. The
                        strength of each member is the team. If you can laugh
                        together, you can work together, silence isn’t golden,
                        it’s deadly.
                      </p>
                    </CardBody> */}
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default AboutUs;
