import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import SettingsHeader from "components/Headers/SettingsHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";

function Settings() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("settings-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("settings-page");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <SettingsHeader />
        <div className="profile-content section">
          <Container>
            <Row>
              <div className="profile-picture">
                <div
                  className="fileinput fileinput-new"
                  data-provides="fileinput"
                >
                  <div className="fileinput-new img-no-padding">
                    <img alt="..." src={require("assets/img/drake.jpg")} />
                  </div>
                  <div className="fileinput-preview fileinput-exists img-no-padding" />
                  <div>
                    <Button
                      className="btn-file btn-round"
                      color="default"
                      outline
                    >
                      <span className="fileinput-new">Change Photo</span>
                      <span className="fileinput-exists">Change</span>
                      <input name="..." type="file" />
                    </Button>
                    <br />
                    <Button
                      className="btn-link fileinput-exists"
                      color="danger"
                      data-dismiss="fileinput"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fa fa-times" />
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="6">
                <Form className="settings-form">
                  <Row>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <label>First Name</label>
                        <Input
                          className="border-input"
                          placeholder="First Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6" sm="6">
                      <FormGroup>
                        <label>Last Name</label>
                        <Input
                          className="border-input"
                          placeholder="Last Name"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <FormGroup>
                    <label htmlFor="inputAddress">Address</label>
                    <Input
                      id="inputAddress"
                      placeholder="1234 Main St"
                      type="text"
                    ></Input>
                  </FormGroup>
                  <FormGroup>
                    <label htmlFor="inputAddress2">Address 2</label>
                    <Input
                      id="inputAddress2"
                      placeholder="Apartment, studio, or floor"
                      type="text"
                    ></Input>
                  </FormGroup>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <label htmlFor="inputCity">City</label>
                      <Input id="inputCity" type="text"></Input>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <label htmlFor="inputState">State</label>
                      <Input id="inputState" type="select">
                        <option selected="">Choose...</option>
                        <option>...</option>
                      </Input>
                    </FormGroup>
                    <FormGroup className="col-md-2">
                      <label htmlFor="inputZip">Zip</label>
                      <Input id="inputZip" type="text"></Input>
                    </FormGroup>
                  </div>

                  <div className="text-center">
                    <Button
                      className="btn-wd btn-round"
                      color="info"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default Settings;
