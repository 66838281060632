import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

// styles
import "./assets/css/bootstrap.min.css";
import "./assets/css/paper-kit.css";

import LandingPage from "./views/examples/LandingPage";
import AboutPage from "./views/examples/AboutUs";
import ContactUs from "views/examples/ContactUs.js";
import RegisterPage from "views/examples/RegisterPage.js";
import Settings from "views/examples/Settings.js";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route path="/" render={(props) => <LandingPage {...props} />} exact />
        <Route
          path="/about-us"
          render={(props) => <AboutPage {...props} />}
          exact
        />
        <Route
          path="/contact-us"
          render={(props) => <ContactUs {...props} />}
        />
        <Route
          path="/register-page"
          render={(props) => <RegisterPage {...props} />}
        />
        <Route path="/settings" render={(props) => <Settings {...props} />} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
