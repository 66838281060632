import React from "react";

// reactstrap components

// core components

function SettingsHeader() {
  return (
    <>
      <div className="page-header page-header-xs settings-background">
        <div className="filter" />
      </div>
    </>
  );
}

export default SettingsHeader;
