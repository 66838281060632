/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import { Container, Row } from "reactstrap";

// core components

// assests
import Logo from "../../assets/logo/tirebank-logo.svg";

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-black">
        <Container>
          <Row>
            <nav className="footer-nav">
              <ul>
                <li>
                  <Link to="/" className="mr-1">
                    Tire Bank
                  </Link>
                </li>
                <li>
                  <Link to="/blog" target="_blank" className="mr-1">
                    Blog
                  </Link>
                </li>
                <li>
                  <a target="_blank">Licenses</a>
                </li>
              </ul>
            </nav>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()} Tire Bank all rights reserved.
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
