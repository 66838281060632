import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
} from "reactstrap";

// core components
const items = [
  {
    src: "",
    content: (
      <CardBody>
        <h5 className="card-description">
          "I have had the pleasure to work with Tire Bank and I could not be
          happier with their service and professionalism. They never cease to
          amaze me how they go out of their way to satisfy my needs from the
          standpoint of convenient for me appointment times, or speedy service,
          or positive conversation or even coming up with ideas such as offering
          second hand tires to me to keep costs down. <br />
          <br />I do not hesitate to recommend them!"
        </h5>
        <CardFooter>
          <CardTitle tag="h4">David C.</CardTitle>

          <div className="card-stars">
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
          </div>
        </CardFooter>
      </CardBody>
    ),
    altText: "",
    caption: "",
  },
  {
    src:
      "https://s3.amazonaws.com/creativetim_bucket/photos/134607/thumb.?1487680276",
    content: (
      <CardBody>
        <h5 className="card-description">
          "Love the shapes and color palette on this one! Perfect for one of my
          pet projects!"
        </h5>
        <CardFooter>
          <CardTitle tag="h4">Robin Leysen</CardTitle>
          <h6 className="card-category">Web Developer</h6>
          <div className="card-stars">
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star mr-1" />
            <i className="fa fa-star" />
          </div>
        </CardFooter>
      </CardBody>
    ),
    altText: "",
    caption: "",
  },
];

function SectionTestimonials() {
  // carousel - TESTIMONIALS 2
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      <div className="section-testimonials cd-section" id="testimonials">
        {/* ********* TESTIMONIALS 1 ********* */}
        <div className="testimonials-1  section-testimonials">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h2 className="title">Our Clients Love Us</h2>
                <h5 className="description">
                  You need more information? Check what other persons are saying
                  about our product. They are very happy with our services.
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-icon">
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">
                      "I have had the pleasure to work with Tire Bank and I
                      could not be happier with their service and
                      professionalism.
                      <br />
                      They never cease to amaze me how they go out of their way
                      to satisfy my needs from the standpoint of convenient for
                      me appointment times, or speedy service, or positive
                      conversation or even coming up with ideas such as offering
                      second hand tires to me to keep costs down. <br />
                      <br />I do not hesitate to recommend them!"
                    </p>
                    <CardFooter>
                      <CardTitle tag="h4">David C.</CardTitle>

                      {/* <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                          />
                        </a>
                      </div> */}
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-icon">
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">
                      "I have used Tire Bank Service for the past four years for
                      all my tire needs on my Tesla Model X and have been more
                      than satisfied with their professional and prompt
                      service!!
                      <br />
                      <br />
                      Danny and his team go out of their way to be sure to
                      provide me with the service I need at the time I ask for !
                      Highly recommend them to all Tesla lovers!"
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                    <CardFooter>
                      <CardTitle tag="h4">Rola</CardTitle>
                      {/* <h6 className="card-category">@ginaandrew</h6> */}
                      {/* <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/faces/joe-gardner-2.jpg")}
                          />
                        </a>
                      </div> */}
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-testimonial">
                  <div className="card-icon">
                    <i className="fa fa-quote-right" />
                  </div>
                  <CardBody>
                    <p className="card-description">
                      "Tire bank provided such a unique service which saved me
                      singificant amount of time for me to change tire. Their
                      team arrived right on time and able to complete their task
                      quick.
                      <br />
                      <br />
                      I would definitly recommend this service to other people.
                      It is a whole new concept that made tire change easy."
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </p>
                    <CardFooter>
                      <CardTitle tag="h4">Jay</CardTitle>
                      {/* <h6 className="card-category">@ginaandrew</h6> */}
                      {/* <div className="card-avatar">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/faces/joe-gardner-2.jpg")}
                          />
                        </a>
                      </div> */}
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/* ********* END TESTIMONIALS 2 ********* */}
      </div>
    </>
  );
}

export default SectionTestimonials;
